<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>车辆调动</el-breadcrumb-item>
      <el-breadcrumb-item>调度记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-dialog title="进程明细" :visible.sync="jinVisible" width="30%" :before-close="handleClose">
        <el-form :model="jin_info" label-width="100px">
          <el-form-item label="申请时间">
            <el-input v-model="jin_info.start_time_at" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="调出项目名称">
            <el-input v-model="jin_info.old_org_name" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="调入项目名称">
            <el-input v-model="jin_info.current_org_name" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="调配车辆类型">
            <el-input v-model="jin_info.use_type_name" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="调配车辆牌号">
            <el-input v-model="jin_info.auto_number" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="申请状态">
            <el-input v-model="jin_info.status_name" autocomplete="off" disabled></el-input>
          </el-form-item>
        </el-form>

        <el-collapse v-model="activeNames">
          <el-collapse-item v-for="(item, index) in logs" :key="index" :name="item.id" :title="item.created_at">
            <div>{{ item.remark }}</div>
          </el-collapse-item>
        </el-collapse>
        <div slot="footer" class="dialog-footer">
          <el-button @click="jinVisible = false">取 消</el-button>
          <el-button type="primary" @click="jinVisible = false">确 定</el-button>
        </div>
      </el-dialog>
      <el-table :data="tableData" style="width: 90%" stripe>
        <el-table-column prop="current_org_name" label="调入项目名称" width="150"></el-table-column>
        <el-table-column prop="add_uid_name" label="调入项目负责人" width="150"></el-table-column>
        <el-table-column prop="use_type_name" label="调配车类型" width="220"></el-table-column>
        <el-table-column prop="auto_number" label="车牌号" width="150"></el-table-column>
        <el-table-column prop="old_org_name" label="调出项目名称" width="150"></el-table-column>
        <el-table-column prop="audit_uid_name" label="调出项目负责人" width="150"></el-table-column>
        <el-table-column prop="end_time_at" label="完成时间" width="100"></el-table-column>
        <el-table-column prop="status" label="操作" width="150">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" @click="jincheng(scope.row.id)">进程明细</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import { Getdiaolie, Getjin } from '../../../api/car'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      logs: [], // 详情数据下半部分
      jinVisible: false, // 进程明细对话框
      jin_info: {}, // 详情数据上半部分
      activeNames: ['1'],
      jinchengid: '', // 进程id
      tableData: [],
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      }
    }
  },
  mounted() {
    this.Getdiaolist()
  },
  methods: {
    // 进程明细
    jincheng(e) {
      console.log(e)
      this.jinchengid = e
      this.Getjincheng()
      this.jinVisible = true
    },
    // 关闭添加弹窗
    handleClose() {
      this.jinVisible = false
    },
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Getdiaolist()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Getdiaolist()
    },

    // 调度申请列表
    async Getdiaolist() {
      const { data } = await Getdiaolie({ show_type: 0, status: 0, ...this.pageData })
      this.tableData = data.data.list
      // console.log(this.tableData)
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
      this.getanpostfun(data, '获取' )
    },

    async Getjincheng() {
      const { data } = await Getjin({ id: this.jinchengid })
      console.log(data)
      this.jin_info = data.data.info
      this.logs = data.data.logs
      console.log(this.logs)
    },

    // 公共方法区└(^o^)┘└(^o^)┘
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          // console.log(123)
          this.handleClose()
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>

<style>
</style>